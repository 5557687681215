import { createStore } from "vuex";

export default createStore({
  state: {
    isLoading: false
  },
  getters: {},
  mutations: {
    updateLoadingState(context: any, value) {
      context.isLoading = value
    }
  },
  actions: {},
  modules: {
  },
});
