<template>
  <div id="app">
    <LoadingCanvas v-if="!store.state.isLoading"></LoadingCanvas>
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import LoadingCanvas from '@/components/LoadingCanvas.vue'
import { useStore } from 'vuex';
const store = useStore()
</script>


<style lang="scss">
@import "./assets/iconfont.css";

html {
  font-size: 1vw;
  width: 100%;
  overflow-x: hidden;
}



* {
  margin: 0;
  padding: 0;
  user-select: none;
}
</style>
