import { createApp, provide } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import element from './utils/elementplus'
// import Particles from 'particles.vue3'
const app = createApp(App)
element(app)

app.use(store).use(router).mount("#app");
