import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [

  {
    path: '/PlatformOption',
    name: 'PlatformOption',
    component: () => import('../views/PlatformOption.vue'),
    meta: {
      title: '连邦科技物联网云平台'
    }
  },
  {
    path: '/',
    redirect: '/platformOption'
  },
];
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
